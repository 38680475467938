
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index4pBxZ4mIPOqGzeWrmKSDYQvqMarXHGask_45C_lrDxDFcMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/index.vue?macro=true";
import { default as login6Drd4fAsDX6mD6nJoNdZ0DgWT5SrUJqZxwxJnUqva24Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyGfDRooSSEACkWjdPdhb_jg34oKmzvDGATMge_OB6ChgMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemap1ak_45_RcB1GOE7m1zbGjLLEkXFYtyLRVdgbCSqFgfIU4Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45uszX6_45sHdtpZnOlOzR2V0WKIsScqW7srY6p7ne_TvPamwMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerKvsm_45ZfI7wzUxOdzH_nCz_45m_pfHBJpgLCt970ubWPqMMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintsi7wRMRrMqrvIEQHu3t4AvTV8uvA5Igq5ZtfbtANOZfQMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45us5jqadUMjEvVUyBZHkEM2zGvxMfp7VmpMTnSI9hyD05UMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexGvATr8E3yDxi5SkUkHB91ZQgtXqXoa14Qw9WF9dwemgMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexQIUpVJP_V_A6Ur3N7hs5YYhevZjH6ZpEDp5RFHgTbrwMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93PUttEsmVMkTjwFmmg921yMOYrnxi3EH8N1FucyvxwgAMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsP2of8c_v41gdGL_45uBq_45bJgvfeGrjAGfzp8ijC_7yKlUMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45use_kymdl5x3CADJBefl_nyNpAz7imZ3XfC5kU1eySWfvgMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexs_b8gppB7HHJ2GFeYHXal3nnaBLAeaTgL0kOwox7NsEMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyZBot5wLyfnrOwHWqJT271OeVHAKy0CruUD9rGb0PgkEMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordRqoGnMeM2SfKATUEhsyElq7i77rDAwYtSp6B85UijagMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexoYKAnr_DqOqvpyZQsjPa0J5YazDbPO5Tn6RXJxJq8vMMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexj3Q96t0rnLg_45LuFHL3giWjetf_45A87HfXpbS7iuPJsrAMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45password0i3XU4G8vjLn4BFG2vKpjb8FPmetGtI0HX7XfZlZj4gMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as errorUROOqgyOYmb_456a_GV9kWyFLR_VsyWw0_45A2aVqKnQCrEMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/cdr/error.vue?macro=true";
import { default as indexwDlY2W8BwQz0SrMmK5CP5PWLqWCbs5ZJYfsqe8c1tVAMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93_459WJMjZ9saiSESzGmAXDwhr_Ojd6rAh8nQauFTD97SEMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guides16BGb7WaHIJu55T1C1DUwIllnpX9Db8p_xlsezREYhEMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45notice3w6frf8VuZpVu3cZoYSk2tmmlP9Iq3khL4TXPK61hqwMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexKeh9ykGRZb1XcWoBMlvjF_455y955Uo6LH0GM0JOvbXBUMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45password7qmTgQS3hXO_p4AtVRFfqMB2DmYk4a4uh3YUgJswZjYMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backOTI01xoK1V4IRiG_45LbbzzaTc4xJYA_450oRBMQTd34y9sMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergenLAI9nSSB0rw0J1a3gnssyVwtLPU_45kvgTbHiDEEO_Y8Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexTJK3IV0ox04nZeftOw83Gj_zf88G59OMNVZsjc0pNQAMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93KVM_ZlMTZk9eT0aN2nheb1DiJFKhAXe_45uKWABkPl_45MkMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youMu3qh1qcHTV6ovi4TCLdjBYkJstNv0jLzql9yxD42dIMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as thank_45youd3N6fxj4S0U7xqvdysjZl8qAJzQy0gndsmDPO36gRa4Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/cdr/thank-you.vue?macro=true";
import { default as indexlik1DD5qtaZrfiR_gA2msBONwB8ytN9g3_po6MI59CQMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexQw_45Qxde5CLp1A0mSZZtYXXWQ_452VxiakZVzcDKcYoWucMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93dE9_efK_037YfFVDGRGODWtgZIPez0CSaUedPYtw8sAMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexjx_Zojv1cgJJZ7DIGuQoTVXx3oOPWHHPhvhFvTw0QCsMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as index2LrUFZR1jz5vT8rEWKklFTRsKGbrT1Ei27F5yklS8ukMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93aWmo2hw7OJBQUAgkmAyRSVcqtdPfWigMWImzGVf8T0wMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackHsyZrQS1bXGQ51iC9dSosLng5sFvNikop8DTXKEjJmwMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimTShTE9IyuhEGwBjld_AopoxOt4TNVnZ7DjT2wwwlPFcMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as index846uMyf_45tIQe4RU_45kQ47HvPxj1fhEy8pu5F81Y1qOQkMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youYUMP9WgTP4cGgPUU53XXZt3HUbuKRt8kreg8969y6mgMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorN9Kh1Vk16lOACUykbuga1FpG1U_kFDaP7anluX97jAEMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexTdYIFd33XARiQ_453z9Gz4ILIYg2unO0lsPDjkSluK9yUMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexuh6BM6FM5fBqG0KE47ktfyyECig_a8ZaPu0wzRbloDUMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorM1IxsAz7P_45rQFVpgkDfjCoH8YwWyvsakNlqGot_p0BkMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93Zf9n_KkDdD3nAkHpLnN8Oppw3sDNaIFeqUkXqGWsUC4Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsROikg65kAdOQL7GQw6iHb0v_lcbtdyhSR48bmgTk2EsMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesT2P0vwTs97egijil_45JawxyeShDARuV8uSIxyNlAx9wkMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93bdptsMQpnItxpAWEq_66HC8bXjYE_45RqKPoCkBTYeZl4Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexBePIiS3Zk65TfzKneYNPw6VfTk1RK4Gr_4560YC6s9eowMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settings1ogqcTVvBHUtMIucFKSfKNL2kPvWKvG6B4AwS9RBYCMMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexrS4u4xtfjfQZGhHhzB3Tgmiv6lcyjNKt10Tc8AdtxY8Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chat4L3eQlsMdBn6UzDBP3Rqve_45uw2sdJv2z4KCxrmrC_2kMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45saleFXwQ93le6QsHHKeXGt0jyU_45NTNYf_453tOdFoRfPQSB38Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youLr3K7oEf_45zVaqGhKjGLRp9JO7U6bIHVbfzKgP3hXiAcMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensiveXoNvsc2b1WlMHcNE3XCYY4n6ZEgkNhWL2zuaNlPNkFsMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93W6Fp9woJGAQHSyUNz16rRO6FAqAv6zBnQiaVJMJXOBMMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addr9cIQ59N5lUfwv1VNj_KrWrui3LXnEntelPzZQxpt8UMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexxQflSIdJFxCjiRWL325xxp_OQhh1a9fNgLbAvq6_a2oMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93YqAPYyKIIXvvprwt79Z2kPKGHBSgPeJiXsF0pyaQRVoMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackMil0lSdmHZJl_45iro4DCdl_suPxTiYT90CVLLWlW5s_YMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93vcg9hg5pLw_bL6rGhlmpxNXOLh1DAnClmwCBRocDvl8Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45yousnM_hzqNknPBoj2MIMoH1V6jjkiu81SAaVPpm_45Pd_8IMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45b57U6_U37s6rO2JOgI8drBwzRBThTtbMYiQozE1HlJ8oMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexWooDQYcixJV2lFDNbdrxPIBGNmE3lGesMac1eI7UDAsMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93iGKBSXDgjBu_Y_45hPasM651dUT8eSTYqgUtS_0tHwvCgMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_932RtHq2lEu84HLrIDOr0K5O_AHRceKCnzSYjVktMUz3EMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexfvWGExnCWXZjDCyIumAAloGsHRKujM1CVF_Tb0nvXOoMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addTu3bxiqvxQibFJwT7lPT6h51fPisirwvWSaxXZqPzUgMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93cN0NJqTkIWFyv_45Bu2FePeUy4hTc3_w2GlZ7XUvvuHRQMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsfYRVvQhX4FI55M4WtocXkUhyYqPNDaGLPPcA66mNukEMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as callbackv0gGCV03qt6LVpAG86LtQajE0yYrqzX8VSJseb6fIeMMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/callback.vue?macro=true";
import { default as digital_45eicBrFyZZG1Liy5RPVBEAA_Ud0epn2TKzaf6uV7ihs86XYMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45you2xAh6UwEylrKq5YntszsAhi_yXUsCZLYKWPgE9MRv8AMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_932j9kdNi3E_P4SeMHxYkLPHlf0I4Nz2Q0oqgrK_zrgL8Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93K_45FHwJ_VM1HwVM1dYFmFAClOIBXNBwsciDEaYxRNwOgMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as comparisonno7eeemBU_LWElthzdYKSFeKUG4mYGPECSU5nkza6aAMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/comparison.vue?macro=true";
import { default as step1m8_45eJ5sn15xfra66iFN2GDYEs6T2f07uYtI6re89D94Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as detailszjirADsSB9HNZXNHX8J9b2r6rGT_QeEvL9bXXAR9jJAMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsjz0zlrhnomFHBCKX5fxScKyNOm4rnO9_45llGoFWx_ZqkMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexFLbNhQdJapwvIcgXF2EhZ30CGxTJMmTsXg9r_doFX7EMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signuptda99GJa3uIdvATFboohI2XyTuHGyKkMklWv_45QRIK2EMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youCTXz6mRtVqlrNE9DFtVVlL2HyXllBgngm9jpw0uDLHkMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93_L9WxCgvXZRU9tndEAfsENTdVtQFlyczPS9Salgd3k8Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93II6qrlGXsbHs61HVmh2z_NcE7aUYsaD2nzpExj33CHIMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0glevO7zraP00tlwINDT2ZetiTXfn2RFfw67Kyu17nUkMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customersOXKjTLFQSFBX6NRtjK5xXSMO28Jd14dfENfe9KT2bqYMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youMtdyWCw_B1k1WKtuY6fgIcP0bFZM2OcClgyaXKtke0QMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsgch88WnPglcAB85gzqapPaJBSGQclnFt2L3WHokuRRoMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as details7OdLfm5r9XjnVAvIEZSyhFHOPjnMwaNTgrRggGlfAVgMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_9360_waKvFSvGM2fiJ7esc7NV1O6Wbc2d5FKpmNpTu2FUMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebateFC8dekhgeebfeG8yjay9LRo_45RwwDxo_JhZIKMDPJJmYMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93XI_45jRx7h9IXyB_457MD4T44AlMB2CTTyR5HcHzGAUwEZwMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsU_hNLCMkd4JCSTQQHBT0qHqxPNmYshbO5RyTbgLy2ToMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0Qqtmb2cz_Y9EFhT14admbxsVnU3kQ3zreWHEz3IrM4EMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexa5lyxI76Ozn44Ce8N17bAxl2xWRScwIYuCu2Ule_45_hcMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexmAlHlI1R3a_45Id_B8BI2Dxfh0f2H7LU0Spm0lTq2g26MMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsfjPLxl_45Nz3phzFOoicrriNp4FGK9D1PGuV5q5bQOx_4Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsCkKfomSBoVOew3qt7ORJlmY_rGkWZYkan4y3NkNa260Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91step_93RVQlNHvhbhuG3tm_454lNSacKZTlOkbHb0qGr47VywrcQMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue?macro=true";
import { default as indexak7jskG2btuF_45G23oCVS09gLrvW1BgjOSzVx6X52wvEMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsZbKoRIJiryYxKUsa38zUWmq0VEup_45bztr0R0KdgtigIMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexx12lxl_z7na35Rcc4CJLEUv56uvdaBC6MUOIo17IZAcMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successWJx3q6PQsWOAPxjYFI4frTLrz7PpdXUcR6usPs71JAcMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offerpqTuBwjLOhvxt_zkX_458YQh5mHuA2OsN6z2aBKSxm8WAMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsH7H2_o7yNmsi0pU9xoaqAU70_45_45KmtIf3Ohgb6x8I08wMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourszpAMNF9Kk4UswrFauoKbwC5h_45nPeEIgY_aLwxwx8HgMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93SkLo2BN6kAizy4j0yjNMsrUhrmPSE9LA7bzfwXloIv8Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as index1QSNYOEE_451dWuTefGe4e53GGWhyhJ_s3TBb44oXdglcMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93rACDwMIltED8iQx8tCGfbLFm4koLIZ_45YWNPVmBoWgMMMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexwJyjocsgdPpO3p7malQpmtziQNyzT5Pdhm_JULM54CcMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93yzqcOuc2sI2B8I55E1hxDORjsZTyRa5i0oS9SE9cbVoMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as thank_45youXSVBra0y9VNy2oKpENXjuzfVsz1DXG9i1KRjpWm4mloMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue?macro=true";
import { default as indexddrwqc_457WpLI6aPJ65vatir4NUytrJ4j7S6jWdD5_8kMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexG23YWpi_MzLeOp8dkN0j9kiZkDCPcgfAFdYaPxvur7UMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsfk2ZARix_JkiK248xGy3qBTqX6YtTHsBEQybLzym7TgMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredVIJ1hirVtg4yPqRZjbWJ9nJR9c4sml8_AiqnABU26YAMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as index9jzacjylU9B74CPTLDQymaJ564OSTT1L77khs3xJ2O4Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45you1Z7o6OkEuF3_453r1wvOrw7KvTsXORUkod7zb9K68jjb8Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsyzFYa7B8AZLRxu1IJJJmX4kXRxMJ6ot980QS7FrMZLsMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as pre_45assesmentUTaRpzvRK6lH1FTc5ispwaRfDlIq_q29oy1DSXm_180Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue?macro=true";
import { default as confirmationA1G6oyr4WuE3NS0kGHRfDY7pMT0S3aSexBFgMlx_45mpcMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionzs3ePhxkb26NJrAek41b60luJ0PbVZMfjNhSicXWSo4Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsky3DDPWdJMMgU1rLsGWZM48K23KqEmMVy5wZxqShGPoMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationSieVsCcRqlUqIPzTiIjIR4KQqw4Hl2HSOO4ZCt0BkP8Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93k4xIj8a2zkwv9gbpNzuVk9nJm3qhkUPI3v_IziEZ9F4Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_937bgo015PV1ZLkUAEhL1lgOxLMjjJPcvWFXg_45IPbT5LIMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93p2nHkZGeVFRVUBtqZirRUOOdiiUF6bDwHvSFXQSLWnMMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93EMbbFdWbpqs3LT78TlYV7yx4DpjCg4x6InA3luPDaRUMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexIDEk9X4BehaVCTEP74mghFzSPDY8YD4fBEt5xIridKIMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45details1ut5rKJMcKHmLh_45ldx4Edmc6Ziicee9DSaMhBYhSMe4Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_936WM05D9eJ4oXiA4N_uHRRw5UoiBZPNYsyKbs_456lxjsMMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93WO6ciKO1Tir4NBy0HEPwNJYBKyU_RiTia5Tz6U3iKJ0Meta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as index1qP8mzPYxJkD7ssnLnXuX69ycXnI2fybl0ocgDiiHLkMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as indexTmBNuvTLrg2vkQRyqa_Dvot9u2TZlENqJsc51hvln1gMeta } from "/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login6Drd4fAsDX6mD6nJoNdZ0DgWT5SrUJqZxwxJnUqva24Meta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerKvsm_45ZfI7wzUxOdzH_nCz_45m_pfHBJpgLCt970ubWPqMMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordRqoGnMeM2SfKATUEhsyElq7i77rDAwYtSp6B85UijagMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexj3Q96t0rnLg_45LuFHL3giWjetf_45A87HfXpbS7iuPJsrAMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password0i3XU4G8vjLn4BFG2vKpjb8FPmetGtI0HX7XfZlZj4gMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "energy-cdr-error",
    path: "/energy/cdr/error",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/cdr/error.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45password7qmTgQS3hXO_p4AtVRFfqMB2DmYk4a4uh3YUgJswZjYMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93KVM_ZlMTZk9eT0aN2nheb1DiJFKhAXe_45uKWABkPl_45MkMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "energy-cdr-thank-you",
    path: "/energy/cdr/thank-you",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/cdr/thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexjx_Zojv1cgJJZ7DIGuQoTVXx3oOPWHHPhvhFvTw0QCsMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: index846uMyf_45tIQe4RU_45kQ47HvPxj1fhEy8pu5F81Y1qOQkMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexuh6BM6FM5fBqG0KE47ktfyyECig_a8ZaPu0wzRbloDUMeta?.props ?? {"verificationNeeded":true},
    meta: indexuh6BM6FM5fBqG0KE47ktfyyECig_a8ZaPu0wzRbloDUMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settings1ogqcTVvBHUtMIucFKSfKNL2kPvWKvG6B4AwS9RBYCMMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexrS4u4xtfjfQZGhHhzB3Tgmiv6lcyjNKt10Tc8AdtxY8Meta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackMil0lSdmHZJl_45iro4DCdl_suPxTiYT90CVLLWlW5s_YMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-callback",
    path: "/life-insurance/quote/callback",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/callback.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-comparison",
    path: "/life-insurance/quote/comparison",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/comparison.vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebateFC8dekhgeebfeG8yjay9LRo_45RwwDxo_JhZIKMDPJJmYMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsfjPLxl_45Nz3phzFOoicrriNp4FGK9D1PGuV5q5bQOx_4Meta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-applicationV2-step",
    path: "/health-insurance/applicationV2/:step()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerpqTuBwjLOhvxt_zkX_458YQh5mHuA2OsN6z2aBKSxm8WAMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsH7H2_o7yNmsi0pU9xoaqAU70_45_45KmtIf3Ohgb6x8I08wMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93SkLo2BN6kAizy4j0yjNMsrUhrmPSE9LA7bzfwXloIv8Meta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "life-insurance-quote-application-thank-you",
    path: "/life-insurance/quote/application/thank-you",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-quote-application-pre-assesment",
    path: "/life-insurance/quote/application/pre-assesment",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionzs3ePhxkb26NJrAek41b60luJ0PbVZMfjNhSicXWSo4Meta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93k4xIj8a2zkwv9gbpNzuVk9nJm3qhkUPI3v_IziEZ9F4Meta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_937bgo015PV1ZLkUAEhL1lgOxLMjjJPcvWFXg_45IPbT5LIMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93EMbbFdWbpqs3LT78TlYV7yx4DpjCg4x6InA3luPDaRUMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45details1ut5rKJMcKHmLh_45ldx4Edmc6Ziicee9DSaMhBYhSMe4Meta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_936WM05D9eJ4oXiA4N_uHRRw5UoiBZPNYsyKbs_456lxjsMMeta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93WO6ciKO1Tir4NBy0HEPwNJYBKyU_RiTia5Tz6U3iKJ0Meta || {},
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-budget-hub-health-insurance-average-cost-calculator",
    path: "/health-insurance/budget-hub/health-insurance-average-cost-calculator",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src2867096980/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]